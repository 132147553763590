import RizkService from '@Services/rizkService'
import { RGTriggers } from '../Common/components/InboxNew/triggers'
import { GameSessionHelper } from '@Utils/gameSessionHelper'
import { loadLiveChat, loadFrameChat, loadHelpCenter } from '@Utils/livechat'
import PassField from '../Common/components/passfield'
import { BehaviorSubject } from 'rxjs'

const rizkApp = require('@Helpers/rizk-app')

var passfield = new PassField()
/**
 *
 * @param {{...any}} props
 * @deprecated Backwards Support for FrontendV1
 */
export function init(props = {}) {
  const { entrypoint } = props
  window.Rizk = window.Rizk || {}
  window.Rizk.isFeatureActive = rizkApp.isFeatureActive
  window.Rizk.isFeatureOn = rizkApp.isFeatureOn
  window.loadLiveChat = loadLiveChat
  window.loadFrameChat = loadFrameChat
  window.loadHelpCenter = loadHelpCenter
  window.checkPassword = passfield.checkPassword
  window.checkPasswordEmail = passfield.checkPasswordEmail
  window.togglePassword = passfield.togglePassword
  window.Rizk.lazyObservable = new BehaviorSubject([])
  window.addEventListener('load', function () {
    const locale = RizkConfig.locale || 'en'

    this.window.Rizk = this.window.Rizk || {}
    this.window.Rizk.rizkService = new RizkService(locale)
    this.window.Rizk.gameSessionHelper = new GameSessionHelper()
    this.window.Rizk.rgTriggers = new RGTriggers()

  })
}
