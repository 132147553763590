import BasePureComponent from '../../../../module/components/Base/BasePureComponent'
import template from '../InboxNew/template.html'
import './inbox.less'
let messages
let msgPointer = 0
let translationKeys = ['trigger.logout', 'trigger.acknowledge']
let translatedCTA
let osmType
export class RGTriggers extends BasePureComponent {
  constructor() {
    super()
    this.getTriggersWithTranslations(RizkConfig.userId, 'rg-trigger')
  }

  getTriggersWithTranslations(userId, osmThemeType) {
    osmType = osmThemeType
    if (
      RizkConfig.checkInbox &&
      Rizk.isFeatureActive('responsible-gaming-triggers', RizkConfig) &&
      RizkConfig.userId === userId.toString()
    ) {
      this.getCTATranslations()
    } else if (
      (osmType === 'withdraw-osm-block' || osmType === 'ad') &&
      RizkConfig.userId === userId.toString()
    ) {
      this.getTriggers()
    }
    {
    }
  }

  getTemplate() {
    return template
  }

  setupListeners(content, acknowledgeCTA) {
    const anchor = content.getElementsByTagName('a')
    const logoutCTA = document.getElementById('trigger-button-logout')
    if (osmType === 'rg-trigger') {
      if (anchor[0]) {
        const rgLink = anchor[0].href
        anchor.onclick = (event) => {
          event.stopPropagation()
          this.closeModal('Redirect to RG Page')
        }
        anchor.ontouchend = (event) => {
          event.stopPropagation()
          this.closeModal('Redirect to RG Page')
          window.location.assign(`${rgLink}`)
        }
        acknowledgeCTA.onclick = (event) => {
          event.stopPropagation()
          this.closeModal('Acknowledge')
        }
        acknowledgeCTA.ontouchstart = (event) => {
          event.stopPropagation()
          this.closeModal('Acknowledge')
        }
        logoutCTA.onclick = (event) => {
          event.stopPropagation()
          this.closeModal('Logged Out')
        }
        logoutCTA.ontouchstart = (event) => {
          event.stopPropagation()
          this.closeModal('Logged Out')
        }
      }
    }
  }

  setUpOsmListeners(content, acknowledgeCTA, isAd = false) {
    const anchor = content.getElementsByTagName('a')
    Array.from(anchor).forEach((element) => {
      element.ontouchend = (event) => {
        event.stopPropagation()
        this.closeModal()
        if (isAd) {
          window.open(element.href, '_blank')
        }else{
          window.location.assign(element.href)
        }
      }
    })
    acknowledgeCTA.onclick = (event) => {
      event.stopPropagation()
      if (anchor[0]) {
        this.closeModal()
        if (isAd) {
          window.open(anchor[0].href, '_blank')
        }else{
        window.location.assign(anchor[0].href)
      }
      } else {
        this.closeModal('reload')
      }
    }
    acknowledgeCTA.ontouchstart = (event) => {
      event.stopPropagation()
      if (anchor[0]) {
        this.closeModal()
        if (isAd) {
          window.open(anchor[0].href, '_blank')
        }else{
          window.location.assign(anchor[0].href)
        }
      } else {
        this.closeModal('reload')
      }
    }
  }

  async getCTATranslations() {
    await ajax(
      '/translations/selection',
      (response) => {
        this.translationsCallback(response).then(() => {
          this.getTriggers()
        })
      },
      translationKeys
    )
  }

  async translationsCallback(translations) {
    translatedCTA = JSON.parse(translations.responseText).payload
    return
  }

  getTriggers() {
    if (RizkConfig.logged === true) {
      let url = `/user/triggers/${osmType}`
      ajax(url, (response) => {
        console.log('RG Triggers response gotten!!!', response)
        if (response.status === 200) {
          var json
          try {
            json = JSON.parse(response.responseText)
          } catch (e) {}
          if (json.length || Object.keys(json).length) {
            this.msgCb(json)
          }
        }
      })
    }
  }

  msgCb(response) {
    this.resetComponentClasses()
    if (Array.isArray(response)) {
      msgPointer = 0
      if (
        typeof response[msgPointer] !== 'undefined' &&
        response[msgPointer].type === 'rg-trigger'
      ) {
        messages = response
        this.showNextMessage()
      }
    } else if (
      osmType === 'withdraw-osm-block' &&
      typeof response === 'object' &&
      response !== null
    ) {
      this.showOsm(response)
    } else if (
      osmType === 'ad' &&
      typeof response === 'object' &&
      response !== null
    ) {
      this.showAd(response)
    }
  }

  resetComponentClasses() {
    let triggerOsmElement = document.getElementsByClassName('rgtrigger')[0]
    if (triggerOsmElement) {
      triggerOsmElement.classList.remove('osm', 'ad')
    }
  }

  closeModal(action) {
    Rizk.ModalController.closeModal('rg-trigger')
    if (!action) {
      return
    }
    if (action !== 'reload') {
      this.markAsRead(action)
    } else if (action === 'reload') {
      location.reload()
    }
  }

  markAsRead(action, msg = messages[msgPointer]) {
    ajax(
      `/user/triggers/action-taken/${msg.id}/${action}/${msg.user_inbox_id}`,
      (response) => {
        try {
          let json = JSON.parse(response.responseText)
          if (
            json &&
            json.response.Success &&
            json.shouldReload &&
            action === 'Acknowledge'
          ) {
            location.reload()
          } else if (json && json.response.Success && action === 'Logged Out') {
            logout()
          } else {
            msgPointer++
            this.showNextMessage()
          }
        } catch (e) {
          console.log(e)
        }
      }
    )
  }

  showNextMessage(translations = translatedCTA, msg = messages[msgPointer]) {
    if (messages.length === msgPointer || msg.type !== 'rg-trigger') {
      console.log('All messages shown!', messages.length, msgPointer)
      return
    }
    if (translations && Object.keys(translations).length > 0) {
      this.createOSM()
      let checkForElement = setInterval(() => {
        if (document.getElementById('trigger-content')) {
          document.getElementById('trigger-button-acknowledge').innerHTML =
            translations['trigger.acknowledge']
          document.getElementById('trigger-button-logout').innerHTML =
            translations['trigger.logout']
          document.getElementById('trigger-title').innerHTML = msg.title
          document.getElementById('trigger-content').innerHTML = msg.text
          this.setupListeners(
            document.getElementById('trigger-content'),
            document.getElementById('trigger-button-acknowledge')
          )
          clearInterval(checkForElement)
        }
      }, 1)
    }
  }

  showOsm(data) {
    this.createOSM()
    let checkForElement = setInterval(() => {
      if (document.getElementById('trigger-content')) {
        document.getElementById('trigger-button-acknowledge').innerHTML =
          data.cta
        document.getElementById('trigger-title').innerHTML = data.title
        document.getElementById('trigger-content').innerHTML = data.content
        let triggerOsmElement = document.getElementsByClassName('rgtrigger')[0]
        if (triggerOsmElement) {
          triggerOsmElement.classList.add('osm')
        }
        this.setUpOsmListeners(
          document.getElementById('trigger-content'),
          document.getElementById('trigger-button-acknowledge')
        )
        clearInterval(checkForElement)
      }
    }, 1)
  }

  showAd(data) {
    this.createOSM()
    let checkForElement = setInterval(() => {
      if (document.getElementById('trigger-content')) {
        document.getElementById('trigger-button-acknowledge').innerHTML =
          data.cta
        document.getElementById('trigger-title').innerHTML = data.title
        document.getElementById('trigger-content').innerHTML = data.content
        let triggerOsmElement = document.getElementsByClassName('rgtrigger')[0]
        if (triggerOsmElement) {
          triggerOsmElement.classList.add('ad')
        }
        this.setUpOsmListeners(
          document.getElementById('trigger-content'),
          document.getElementById('trigger-button-acknowledge'),
          true
        )
        clearInterval(checkForElement)
      }
    }, 1)
  }

  createOSM() {
    const id = 'rg-trigger'
    Rizk.ModalController.closeModal(id)
    if (Rizk.ModalController.getModal(id) === undefined) {
      const componentFragment = '<inbox-trigger></inbox-trigger>'
      var modal = Rizk.ModalFactory.createFromWebComponent(
        id,
        componentFragment
      )
      Rizk.ModalController.addModal(modal)
    }
    Rizk.ModalController.showModal(id, 5)
  }
}
window.customElements.define('inbox-trigger', RGTriggers)
