module.exports = {
  isFeatureActive,
  isFeatureOn,
  rizkCacheSet,
  rizkCacheGet,
}

function isFeatureActive(name, rizkConfig) {
  if (!rizkConfig.flaggedFeatures || !rizkConfig.flaggedFeatures[name]) {
    return true
  }

  const feature = rizkConfig.flaggedFeatures[name]
  const strategy = feature.strategy || 'allow'
  const countryCode = rizkConfig.country.toUpperCase()
  const licences = rizkConfig.licencesAuthority
  const licence = licences[countryCode] || rizkConfig.defaultAuthority
  const result = feature.licences.includes(licence)

  return strategy === 'allow' ? result : !result
}

function isFeatureOn(name, rizkConfig) {
  const features = rizkConfig.toggeledFeatures

  if (!features || !features[name]) {
    return false
  }

  const userCountryCode = rizkConfig.country
  const userId = rizkConfig.userId

  if (features[name].users === 'all' && features[name].countries === 'all') {
    return true
  }
  const userArray = features[name].users.replace(/\s/g, '').split(',')
  if (userArray.includes(userId)) {
    return true
  }

  const countryArray = features[name].countries
    .replace(/\s/g, '')
    .toLowerCase()
    .split(',')
  if (
    features[name].users === 'all' &&
    countryArray.includes(userCountryCode.toLowerCase())
  ) {
    return true
  }

  return false
}

/**
 * Set a value in local storage with an expiry time.
 * 
 * @param {string}  key     The key to set the value for.
 * @param {any}     value   The value to set.
 * @param {number}  ttl     The time to live in seconds (default 1 day).
 * @returns {void}
 */
function rizkCacheSet(key, value, ttl=86400) {
  key = 'rizk-cache:' + key;

  const expiry = parseInt((new Date().getTime() / 1000)) + parseInt(ttl);

  sessionStorage.setItem(key, JSON.stringify({
    expiry,
    value: value
  }));
}

/**
 * Get a value from local storage.
 *
 * @param {string}  key     The key to get the value for.
 * @return  {any}
 */
function rizkCacheGet(key) {
  key = 'rizk-cache:' + key;

  const itemStr = sessionStorage.getItem(key);
  if (!itemStr) {
      return null;
  }

  const item = JSON.parse(itemStr);

  if (parseInt((new Date()).getTime() / 1000) > parseInt(item.expiry)) {
      sessionStorage.removeItem(key);
      return null;
  }

  return item.value;
}