/**
 *
 * @param {Function} done
 * @note Currently this is dependent on values being in the "RizkConfig" which we are moving away from
 * Implemention should accept required properties as function arguments
 */
export function loadLiveChat(done, chatLocale) {
  function overwritePopupBehaviour() {
    var originalOpenForm = LiveAgent.PopupForm.prototype.show
    LiveAgent.PopupForm.prototype.show = function () {
      originalOpenForm.apply(this, arguments)
      LiveAgent.instance.closeOpenedWidget()
    }
  }

  ;(function (d, src, c) {
    var t = d.scripts[d.scripts.length - 1],
      s = d.createElement('script')
    s.id = 'la_x2s6df8d'
    s.async = true
    s.src = src
    s.onload = s.onreadystatechange = function () {
      var rs = this.readyState
      if (rs && rs !== 'complete' && rs !== 'loaded') {
        return
      }
      c(this)
    }
    t.parentElement.insertBefore(s, t.nextSibling)
  })(document, '//support.betitgroup.com/scripts/track.js', function (e) {
    if (RizkConfig.logged) {
      LiveAgent.addContactField('UserID', RizkConfig.userId)
      LiveAgent.setUserDetails(
        RizkConfig.userEmail,
        RizkConfig.userFirstName,
        RizkConfig.userLastName,
        ''
      )
    } else {
      LiveAgent.addContactField('UserID', '')
      LiveAgent.setUserDetails('', '', '', '')
    }

    // var chatLocale =
    //   typeof LIVE_AGENT_IDS[RizkConfig.locale] === 'undefined'
    //     ? LIVE_AGENT_IDS['en']
    //     : LIVE_AGENT_IDS[RizkConfig.locale]
    window.chatButton = LiveAgent.createButton(chatLocale, e)

    function wait() {
      setTimeout(() => {
        try {
          overwritePopupBehaviour()
          done()
        } catch (e) {
          wait()
        }
      }, 200)
    }

    wait()
  })
}

export function loadFrameChat() {
  var locale = RizkConfig.locale === 'gb' ? 'en' : RizkConfig.locale
  var url = 'https://bml-' + locale + '.custhelp.com/app/chat/rizk/form'

  if (RizkConfig.device === 'mobile') {
    window.open(url, '_blank')
  } else {
    document.getElementById('chat-modal').style.display = 'block'
    document.getElementById('chat-iframe').src = url
  }
  return false
}

export function loadHelpCenter() {
  var chatLinks = {
    row: {
      locales: ['no', 'de', 'nz', 'ca', 'gb', 'pl', 'is'],
      baseUrl: 'https://support.rizk.com',
      polandBaseUrl: 'https://support.rizk03.com',
      germanBaseUrl: 'https://support.rizkslots.de'
    },
    es: 'https://support.rizk.es',
    hr: 'https://support.rizk.hr',
    sr: 'https://podrska.rizk.rs',
    pe: 'https://support.inkabet.pe',
  }

  var redirectURL = chatLinks.row.baseUrl
  var hasLocale = hasRowLocale(RizkConfig.locale)

  if (
    !Object.keys(chatLinks).includes(RizkConfig.locale) &&
    !hasLocale &&
    RizkConfig.device === 'mobile'
  ) {
    redirectURL = `${redirectURL}/mobile/`
  }
  if (Object.keys(chatLinks).includes(RizkConfig.locale)) {
    if (RizkConfig.device === 'mobile') {
      redirectURL = `${chatLinks[RizkConfig.locale]}/mobile/`
    } else {
      redirectURL = `${chatLinks[RizkConfig.locale]}/`
    }
  } else if (hasLocale) {
    redirectURL = generateSupportUrlByLocale(redirectURL, RizkConfig.locale)
  }
  window.open(redirectURL, '_blank')

  function hasRowLocale(userLocale) {
    return chatLinks.row.locales.includes(userLocale)
  }

  function generateSupportUrlByLocale(supportUrl, locale) {
    if (locale === 'pl') {
      supportUrl = chatLinks.row.polandBaseUrl
    } else if (locale === 'de') {
      supportUrl = chatLinks.row.germanBaseUrl
    }
    return RizkConfig.device === 'mobile'
      ? `${supportUrl}/mobile/${locale}`
      : `${supportUrl}/${locale}`
  }
}
