export default class PassField {
  checkPassword(pass) {
    var p = pass
    var regexLength = /^.{8,25}$/
    var regexNumber = /^(?=.*\d)/
    var regexSymbol = /^(?=.*?[.*~{[\]}=(<?!@\$%\^&#,+_>)|-])/
    var regexUpperLower = /^(?=.*[A-Z])(?=.*[a-z])/

    regexLength.test(p)
      ? document.getElementById('pLength').classList.add('satisfied')
      : document.getElementById('pLength').classList.remove('satisfied')
    regexNumber.test(p)
      ? document.getElementById('pNumber').classList.add('satisfied')
      : document.getElementById('pNumber').classList.remove('satisfied')
    regexSymbol.test(p)
      ? document.getElementById('pSymbol').classList.add('satisfied')
      : document.getElementById('pSymbol').classList.remove('satisfied')
    regexUpperLower.test(p)
      ? document.getElementById('pUpperLower').classList.add('satisfied')
      : document.getElementById('pUpperLower').classList.remove('satisfied')
  }

  checkPasswordEmail() {
    var e = document.getElementById('form_user_email').value
    var p = document.getElementById('form_user_password').value
    var n = e.split('@')[0]
    if (
      e.toLowerCase() !== p.toLowerCase() &&
      n.toLowerCase() !== p.toLowerCase()
    ) {
      return true
    }
    return false
  }

  togglePassword(event, fieldId, trigger = 'toggle-password') {
    event.preventDefault()
    var password = document.getElementById(fieldId)
    var textswrap = document.getElementById(trigger)
    var texts = textswrap.children

    texts[0].classList.toggle('hidden')
    texts[1].classList.toggle('hidden')

    if (password.getAttribute('type') === 'password') {
      password.setAttribute('type', 'text')
    } else {
      password.setAttribute('type', 'password')
    }
  }
}
