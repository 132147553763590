/**
 *
 * @param {HTMLElement} elem
 */
export function showClock(elem) {
  if (elem === null) {
    return
  }
  var d = new Date(),
    hour = d.getHours(),
    min = d.getMinutes()
  if (hour < 10) {
    hour = '0' + hour
  }
  if (min < 10) {
    min = '0' + min
  }
  elem.innerHTML = hour + ':' + min
  setTimeout(showClock.bind(null, elem), (60 - d.getSeconds()) * 1e3)
}

/**
 *
 * @param {DateString} timestamp
 * @param {Boolean} seconds
 */
export function convertTimestamp(timestamp, seconds) {
  // Convert timestamp to milliseconds
  var date = new Date(timestamp * 1000)
  // Year
  var year = date.getFullYear()
  // Month
  var month = date.getMonth() + 1
  // Day
  var day = date.getDate()
  // Hours
  var hours = date.getHours()
  // Minutes
  var minutes = '0' + date.getMinutes()
  if (seconds === true) {
    // Seconds
    var seconds = '0' + date.getSeconds()
    // Display date time in MM-dd-yyyy h:m:s format
    var convdataTime =
      day +
      '.' +
      month +
      '.' +
      year +
      ' ' +
      hours +
      ':' +
      minutes.substr(-2) +
      ':' +
      seconds.substr(-2)
  } else {
    var convdataTime =
      day + '.' + month + '.' + year + ' ' + hours + ':' + minutes.substr(-2)
  }
  return convdataTime
}

/**
 * @deprecated Should be Moved to RizkService
 */
export function lastLogin() {
  if (RizkConfig.logged && Rizk.isFeatureActive('last-logged-in', RizkConfig)) {
    ajax('/user/login-history', x => {
      if (x.status === 200) {
        var json = JSON.parse(x.responseText)
        if (
          json.loginHistory !== false &&
          typeof json.loginHistory !== 'undefined'
        ) {
          var lastLoginTime = convertTimestamp(json.loginHistory, false)
          var nodes = document.getElementsByClassName('lastlogin')
          for (i = 0; i < nodes.length; i++) {
            nodes[i].innerHTML = lastLoginTime
          }
        }
      }
    })
  } else {
    return false
  }
}

/**
 *
 * @param {String} value
 */
export function parseStringToNode(value) {
  const placeholder = document.createElement('div')
  placeholder.insertAdjacentHTML('afterbegin', value)
  return placeholder.firstElementChild
}

export function getAppConfig() {
  return window.RizkConfig || {}
}


/**
 *
 * @param {Function} callback
 * @param {Number} wait
 */
export function debounce(callback, wait) {
  let timeout
  return function(...args) {
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      clearTimeout(timeout)
      callback(...args)
    }, wait)
  }
}

/**
 * @see https://github.com/sindresorhus/p-debounce/blob/master/index.js
 * @param {Function} fn
 * @param {Number} wait
 * @param {{leading: Boolean}} options
 */
export function debounceAsync(fn, wait, options = {}) {
  if (!Number.isFinite(wait)) {
    throw new TypeError('Expected `wait` to be a finite number')
  }

  let leadingValue
  let timer
  let resolveList = []

  return function(...arguments_) {
    return new Promise(resolve => {
      const runImmediately = options.leading && !timer

      clearTimeout(timer)

      timer = setTimeout(() => {
        timer = null

        const result = options.leading
          ? leadingValue
          : fn.apply(this, arguments_)

        for (resolve of resolveList) {
          resolve(result)
        }

        resolveList = []
      }, wait)

      if (runImmediately) {
        leadingValue = fn.apply(this, arguments_)
        resolve(leadingValue)
      } else {
        resolveList.push(resolve)
      }
    })
  }
}

/**
 *
 * @param {HTMLElement} prev
 * @param {HTMLElement} next
 */
export function moveNodes(prev, next) {
  if (prev instanceof HTMLElement && next instanceof HTMLElement) {
    while (prev.childNodes.length) {
      next.appendChild(prev.firstChild)
    }
  }
}

/**
 *
 * @param {Number} milliseconds
 */
export function sleep(milliseconds) {
  return new Promise(function(resolve) {
    setTimeout(resolve, milliseconds)
  })
}

/**
 *
 * @param {HTMLElement} node
 * @param {Function} cb
 * @param {{attributes: Boolean,
    childList: Boolean,
    subtree: Boolean}} config
 */
export function createObserver(node, cb, config = {}) {
  const defaults = {
    attributes: true,
    childList: false,
    subtree: false,
    ...config,
  }
  if (typeof cb === 'function') {
    const observer = new MutationObserver(cb)
    observer.observe(node, defaults)
    return observer
  }
  return null
}

/**
 *
 * @param {{target: {elements: NodeList}}} event
 */
export function populateFormDataFromEvent(event) {
  const formData = event.target.elements
  const data = {}
  for (var i = 0; i < formData.length; i++) {
    const field = formData[i]
    if (field.type === 'submit') {
      continue
    }
    data[field.name] = field.value
  }
  return data
}

/**
 *
 * @param {*} obj
 */
export function deepClone(obj) {
  if (obj === null || typeof obj !== 'object' || 'isActiveClone' in obj) {
    return obj
  }

  if (obj instanceof Date) {
    return new obj.constructor(obj)
  }

  let temp = obj.constructor()
  for (let key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      obj['isActiveClone'] = null
      temp[key] = deepClone(obj[key])
      delete obj['isActiveClone']
    }
  }
  return temp
}

/**
 *
 * @param {Number} length
 */
export function generateId(length = 10) {
  // Math.random().toString(36).substring(7)
  return [...Array(length)]
    .map(i => (~~(Math.random() * 36)).toString(36))
    .join('')
}
